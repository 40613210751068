.wrap-header-projects{
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  border-radius: @border-radius;
  border: 1px solid @golden;
  h1{
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: @gray-moon;
    padding: 3rem 0;
  }
}
.wrap-project-item{
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  .wrap-content-project-item{
    width: 71%;
    display: flex;
    flex-direction: column;
  }
  .header-project-item{
    border-radius: @border-radius;
    border: 1px solid @golden;
    padding: 2.5rem ;
    margin-top: 3rem ;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    h1{
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: @gray-moon;
    }
    p{
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      margin: 0;
      line-height: 150%;
      color: @gray-moon;
    }

  }
  .content-project-item{
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
    img{
      width: 100%;
      &:last-child{
        border-radius: 0 0 @border-radius @border-radius;
      }
      &:first-child{
        border-radius: @border-radius @border-radius 0 0;
      }
    }
  }
  .sidebar-sticky{
    position: sticky;
    --offset: 2rem;
    //flex-grow: 1;
    //flex-basis: 300px;
    align-self: start;
    top: var(--offset);
    width: 20%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: @dark-gray;
    border-radius: @border-radius;
    color: @gray-moon;
    padding: 2.5rem;
    //height: fit-content;
    p{
      font-size: 1rem;
      font-style: normal;
      font-weight: 800;
      line-height: 150%; /* 1.875rem */
      letter-spacing: 0.0625rem;
      margin: 0;
    }
    a{
      width: 100%;
      //background: @golden;
      color: @dark-blue;
      text-transform: uppercase;
      height: 40px;
      display: flex;
      margin-top: 2rem;
      font-size: 0.875rem;
      text-align: center;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      border-radius: @border-radius;
      border: 1px solid @golden;
      font-weight: 500;
      text-decoration: none;
      justify-content: center;
      &:hover{
        cursor: pointer;
        color: @golden;
      }
    }
  }
}
.projects-tags{
  display: flex;
  flex-wrap: wrap;
  a{
    display: flex;
    color: @golden;
    border-radius: @border-radius;
    background: @dark-gray;
    text-decoration: none;
    padding: 10px 25px;
    border: 1px solid @dark-gray;
    transition: .5s;
    &:hover{
      transition: .5s;
      border: 1px solid @golden;
    }
  }
}