.header{
  margin-top: @margin-size;
  .wrap-header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .logo-header{
    width: 150px;
    transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
    &:hover{
      transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
      opacity: .6;
    }
  }
  ul{
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    li{
      display: flex;
      margin: 0 2rem;
    }
  }
  a{
    color: @golden;
    text-decoration: none;
    letter-spacing: 1px;
  }
}



