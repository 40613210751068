body {
  font-family: 'Montserrat', sans-serif;
  background: #ffffff;
  font-size: 16px;
  color: #F0BF6C;
  padding: 0;
  margin: 0;
  background: #34353A;
}
::selection {
  color: #F1E9E6;
  background: #F0BF6C;
}
html {
  overflow: auto;
}
p {
  margin: 0;
  padding: 0;
}
.not-home {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}
input:focus,
button:focus,
textarea:focus {
  outline: none;
  transition: 0.6s;
}
/* Let's get this party started */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #F0BF6C;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #F0BF6C;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 80% !important;
}
.w-70 {
  width: 70% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-40 {
  width: 40% !important;
}
.w-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}
.mb-150 {
  margin-bottom: 150px;
}
.breadcrumbs ul {
  padding-left: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}
.breadcrumbs ul li {
  list-style: none;
  color: #868686;
  padding: 0 3px;
  display: inline-block;
  font-weight: 300;
  font-size: 14px;
}
.breadcrumbs ul li a {
  color: #F0BF6C;
  font-weight: 300;
  font-size: 14px;
  padding: 0;
}
.breadcrumbs ul li a:hover {
  text-decoration: none;
}
.wrap-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container {
  max-width: 85rem;
  width: 100%;
}
.title-block-before {
  height: 155px;
  width: 10px;
  background: #F0BF6C;
  display: flex;
  margin-right: 20px;
}
.wrap-title-all {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.wrap-title-all h1 {
  display: flex;
  font-family: 'Monserat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 125%;
}
.btn-link-wbg {
  border: 1px solid;
  padding: 0.7rem 1.5rem;
  border-radius: 2.5rem;
  color: #F0BF6C;
  background: linear-gradient(to left, rgba(255, 191, 108, 0) 25%, rgba(240, 191, 108, 0.9) 75%) 100% 100%;
  background-size: 400% 300%;
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.btn-link-wbg:hover {
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #34353A;
  background-position: 1% 100%;
}
.btn-link-bg {
  background: linear-gradient(to left, rgba(255, 191, 108, 0) 25%, rgba(240, 191, 108, 0.9) 75%) 1% 100%;
  background-size: 400% 300%;
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.btn-link-bg:hover {
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
  background-position: 100% 100%;
}
.underline-btn {
  padding: 0.5em 0;
  border-radius: 0;
  text-decoration: none;
  position: relative;
}
.underline-btn:before {
  transition: width 0s ease, background 0.4s ease;
  left: 0;
  right: 0;
  bottom: 3px;
  content: "";
  display: block;
  position: absolute;
  height: 0.5px;
  width: 0;
}
.underline-btn:hover:after {
  width: 97.8%;
  background: 0 0;
  transition: all 0s ease;
}
.underline-btn:hover:before {
  width: 97.8%;
  background: #F0BF6C;
  transition: width 0.4s ease;
}
.underline-btn:after {
  content: "";
  display: block;
  position: absolute;
  height: 0.5px;
  width: 0;
  right: 2.2%;
  bottom: 3px;
  background: #F0BF6C;
  transition: width 0.4s ease;
}
.underline-hover-btn {
  text-decoration: none;
  position: relative;
}
.underline-hover-btn:hover:before {
  opacity: 0;
  transform: translateY(6px);
  transition-duration: 0.4s;
}
.underline-hover-btn:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.5px;
  left: 0;
  bottom: 0px;
  transform: none;
  opacity: 1;
  transition: 1.8s cubic-bezier(0.2, 1, 0.3, 1);
  pointer-events: none;
}
.wrap-breadcrumbs {
  display: flex;
  margin-top: 2rem;
}
.wrap-breadcrumbs ul {
  list-style: none;
  padding-left: 0;
  color: #F1E9E6;
  display: flex;
  gap: 7px;
  font-size: 0.875rem;
}
.wrap-breadcrumbs ul a {
  color: #F1E9E6;
  font-size: 0.875rem;
}
.wrap-breadcrumbs ul a:hover {
  text-decoration: none;
}
.wrap-breadcrumbs ul a:before {
  background: #F1E9E6;
}
.header {
  margin-top: 3.125rem;
}
.header .wrap-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.header .logo-header {
  width: 150px;
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
}
.header .logo-header:hover {
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0.6;
}
.header ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.header ul li {
  display: flex;
  margin: 0 2rem;
}
.header a {
  color: #F0BF6C;
  text-decoration: none;
  letter-spacing: 1px;
}
.wrap-main-screen {
  display: flex;
  height: 75vh;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
}
.wrap-text-ms {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.wrap-text-ms .links-ms {
  margin-top: 4rem;
}
.wrap-text-ms .links-ms ul {
  padding-left: 0;
  display: flex;
}
.wrap-text-ms .links-ms ul li {
  display: flex;
  flex-wrap: wrap;
  margin-right: 3.125rem;
}
.wrap-text-ms .links-ms a {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.0125rem;
  text-decoration-line: underline;
  color: #F0BF6C;
  text-decoration: none;
}
.wrap-text-ms .links-ms a img {
  margin-right: 10px;
}
.wrap-text-ms .links-ms span:before {
  background: #F0BF6C;
}
.wrap-text-ms .text-ms {
  width: 100%;
}
.wrap-text-ms .text-ms h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 4.875rem;
  color: #F1E9E6;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.24375rem;
}
.wrap-text-ms .text-ms span {
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.03125rem;
  display: flex;
  width: 100%;
  color: #F1E9E6;
  margin-top: 2rem;
}
.wrap-img-ms {
  width: 35%;
  height: 90vh;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
}
#projects,
#skills {
  margin-top: 2rem;
}
#projects h2,
#skills h2 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  color: #F1E9E6;
}
#projects {
  margin-top: 6rem;
}
.wrap-all-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3.125rem;
}
.project-item {
  width: 26.6875rem;
  display: flex;
  margin-bottom: 2.5rem;
  height: 18.25rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2.5rem;
}
.project-link-all {
  width: 26.6875rem;
  display: flex;
  height: 18.25rem;
  background: #3D3E42;
  border-radius: 2.5rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  text-align: center;
  color: #F0BF6C;
  transition: all 0.5s;
  align-items: center;
  justify-content: center;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.07813rem;
}
.project-link-all:hover {
  transition: all 0.5s;
  background: #F0BF6C;
  color: #34353A;
  box-shadow: none;
}
.project-item-link {
  border-bottom: none;
  cursor: pointer;
  display: block;
  border-radius: 2.5rem;
  overflow: hidden;
  height: 100%;
  position: relative;
}
.project-item-link:before {
  background: rgba(0, 0, 0, 0.8);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  z-index: 3;
}
.project-item-link:hover:before {
  opacity: 1;
}
.project-item-link:hover .project-img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.project-item-link:hover .project-item-desc {
  opacity: 1;
}
.project-item-link:hover .project-item-desc-title {
  -webkit-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1);
}
.project-item-link:hover .project-item-desc-text {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  color: #F1E9E6;
}
.project-img {
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 2.5rem;
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  width: 100%;
}
.project-item-desc {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  color: #F1E9E6;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 50%;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 4;
}
.project-item-desc-title {
  color: #F1E9E6;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding: 0 10px;
  text-transform: uppercase;
  -webkit-transform: translateY(-30px) scale(1.5);
  transform: translateY(-30px) scale(1.5);
  -webkit-transition: all 1s;
  transition: all 1s;
  will-change: transform;
  z-index: 4;
}
.project-item-desc-text {
  color: #fff;
  font-size: 20px;
  padding: 0 10px;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 1s;
  transition: all 1s;
  will-change: transform;
}
.wrap-all-skills {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.wrap-all-skills .skills-item {
  display: flex;
  width: 8.1rem;
  padding: 1.5rem 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.875rem;
  flex-shrink: 0;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  background: #3D3E42;
  border-radius: 2.5rem;
  transition: all 0.5s;
}
.wrap-all-skills .skills-item:nth-child(9n) {
  margin-right: 0;
}
.wrap-all-skills .skills-item:hover {
  transition: all 0.5s;
  background: none;
}
.wrap-all-skills .skills-desc {
  color: #F1E9E6;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  margin: 0;
}
.wrap-all-skills .skills-img {
  width: 5.2rem;
  border-radius: 1rem;
}
.wrap-footer {
  font-family: 'Montserrat', sans-serif;
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
.wrap-footer .links-footer {
  width: 20%;
}
.wrap-footer .links-footer ul {
  padding-left: 0;
  list-style: none;
}
.wrap-footer .links-footer ul li {
  margin-bottom: 1rem;
}
.wrap-footer .links-footer a {
  color: #F1E9E6;
  font-size: 1rem;
  text-decoration: none;
}
.wrap-footer .links-footer a:before {
  background: #F1E9E6;
}
.wrap-footer .contacts-footer {
  width: 50%;
}
.wrap-footer .contacts-footer h2 {
  color: #F1E9E6;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}
.wrap-footer .contacts-footer ul {
  margin-top: 2rem;
  padding-left: 0;
  list-style: none;
}
.wrap-footer .contacts-footer ul li {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.wrap-footer .contacts-footer ul li img {
  margin-right: 1rem;
}
.wrap-footer .contacts-footer ul li a {
  color: #F1E9E6;
  font-size: 1rem;
  text-decoration: none;
}
.wrap-footer .contacts-footer ul li a:before {
  background: #F1E9E6;
}
.wrap-footer .form-footer {
  width: 30%;
}
.wrap-footer .form-footer .input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
}
.wrap-footer .form-footer .input-box label {
  color: #F1E9E6;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.04375rem;
  text-transform: uppercase;
  margin-bottom: 0.62rem;
}
.wrap-footer .form-footer .input-box input {
  height: 40px;
  border-radius: 2.5rem;
  background: none;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  border: 1px solid #F1E9E6;
  color: #F1E9E6;
  padding-left: 1.5rem;
}
.wrap-footer .form-footer .input-box textarea {
  font-family: 'Montserrat', sans-serif;
  height: 150px;
  border-radius: 2.5rem;
  font-size: 1rem;
  letter-spacing: 1px;
  border: 1px solid #F1E9E6;
  color: #F1E9E6;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  background: none;
}
.wrap-footer input[type="submit"] {
  width: 100%;
  color: #34353A;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  border-radius: 2.5rem;
  border: 1px solid #F0BF6C;
  font-weight: 500;
}
.wrap-footer input[type="submit"]:hover {
  cursor: pointer;
  color: #F0BF6C;
}
.wrap-header-projects {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  border-radius: 2.5rem;
  border: 1px solid #F0BF6C;
}
.wrap-header-projects h1 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  color: #F1E9E6;
  padding: 3rem 0;
}
.wrap-project-item {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrap-project-item .wrap-content-project-item {
  width: 71%;
  display: flex;
  flex-direction: column;
}
.wrap-project-item .header-project-item {
  border-radius: 2.5rem;
  border: 1px solid #F0BF6C;
  padding: 2.5rem;
  margin-top: 3rem ;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
.wrap-project-item .header-project-item h1 {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  color: #F1E9E6;
}
.wrap-project-item .header-project-item p {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  margin: 0;
  line-height: 150%;
  color: #F1E9E6;
}
.wrap-project-item .content-project-item {
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
}
.wrap-project-item .content-project-item img {
  width: 100%;
}
.wrap-project-item .content-project-item img:last-child {
  border-radius: 0 0 2.5rem 2.5rem;
}
.wrap-project-item .content-project-item img:first-child {
  border-radius: 2.5rem 2.5rem 0 0;
}
.wrap-project-item .sidebar-sticky {
  position: sticky;
  --offset: 2rem;
  align-self: start;
  top: var(--offset);
  width: 20%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #3D3E42;
  border-radius: 2.5rem;
  color: #F1E9E6;
  padding: 2.5rem;
}
.wrap-project-item .sidebar-sticky p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  /* 1.875rem */
  letter-spacing: 0.0625rem;
  margin: 0;
}
.wrap-project-item .sidebar-sticky a {
  width: 100%;
  color: #34353A;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  margin-top: 2rem;
  font-size: 0.875rem;
  text-align: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  border-radius: 2.5rem;
  border: 1px solid #F0BF6C;
  font-weight: 500;
  text-decoration: none;
  justify-content: center;
}
.wrap-project-item .sidebar-sticky a:hover {
  cursor: pointer;
  color: #F0BF6C;
}
.projects-tags {
  display: flex;
  flex-wrap: wrap;
}
.projects-tags a {
  display: flex;
  color: #F0BF6C;
  border-radius: 2.5rem;
  background: #3D3E42;
  text-decoration: none;
  padding: 10px 25px;
  border: 1px solid #3D3E42;
  transition: 0.5s;
}
.projects-tags a:hover {
  transition: 0.5s;
  border: 1px solid #F0BF6C;
}
