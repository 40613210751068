.wrap-main-screen{
  display: flex;
  height: 75vh;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
}
.wrap-text-ms{
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  .links-ms{
    margin-top: 4rem;
    ul{
      padding-left: 0;
      display: flex;
      li{
        display: flex;
        flex-wrap: wrap;
        margin-right: @margin-size;
      }
    }
    a{
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.0125rem;
      text-decoration-line: underline;
      color: @golden;
      text-decoration: none;
      img{
        margin-right: 10px;
      }
    }
    span{
      &:before{
        background:@golden;
      }
    }
  }
  .text-ms{
    width: 100%;
    h1{
      font-family: @font-name;
      font-size: 4.875rem;
      color: @gray-moon;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.24375rem;
    }
    span{
      font-size: 3.125rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.03125rem;
      display: flex;
      width: 100%;
      color: @gray-moon;
      margin-top: 2rem;
    }
  }
}
.wrap-img-ms{
  width: 35%;
  height: 90vh;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
}

#projects, #skills{
  margin-top: 2rem;
  h2{
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: @gray-moon;
  }
}
#projects{
  margin-top: 6rem;
}

.wrap-all-projects{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: @margin-size;
}
.project-item{
  width: 26.6875rem;
  display: flex;
  margin-bottom: 2.5rem;
  height: 18.25rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: @border-radius;
}
.project-link-all{
  width: 26.6875rem;
  display: flex;
  height: 18.25rem;
  background: @dark-gray;
  border-radius: @border-radius;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  text-align: center;
  color: @golden;
  transition:all .5s;
  align-items: center;
  justify-content: center;
  font-size: 1.5625rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.07813rem;
  &:hover{
    transition: all .5s;
    background: @golden;
    color: @dark-blue;
    box-shadow: none;
  }
}
.project-item-link {
  border-bottom:none;
  cursor:pointer;
  display:block;
  border-radius: @border-radius;
  overflow: hidden;
  height:100%;
  position:relative;
  &:before {
    background:rgba(0,0,0,.8);
    content:"";
    height:100%;
    left:0;
    opacity:0;
    position:absolute;
    top:0;
    -webkit-transition:all .5s;
    transition:all .5s;
    width:100%;
    z-index:3
  }
  &:hover:before {
    opacity:1
  }
  &:hover .project-img {
    -webkit-transform:scale(1.3);
    transform:scale(1.3)
  }
  &:hover .project-item-desc{
    opacity:1
  }
  &:hover .project-item-desc-title {
    -webkit-transform:translateY(0) scale(1);
    transform:translateY(0) scale(1)
  }
  &:hover .project-item-desc-text {
    -webkit-transform:translateY(0);
    transform:translateY(0);
    color:@gray-moon;
  }
}


.project-img {
  display:block;
  height:100%;
  -o-object-fit:cover;
  object-fit:cover;
  border-radius: @border-radius;
  -webkit-transition:-webkit-transform .8s;
  transition:-webkit-transform .8s;
  transition:transform .8s;
  transition:transform .8s,-webkit-transform .8s;
  width:100%
}
.project-item-desc {
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
  -webkit-box-pack:center;
  color:@gray-moon;
  -ms-flex-pack:center;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -ms-flex-direction:column;
  flex-direction:column;
  height:100%;
  justify-content:center;
  left:50%;
  opacity:0;
  position:absolute;
  text-align:center;
  top:50%;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  -webkit-transition:opacity .5s;
  transition:opacity .5s;
  width:100%;
  z-index:4
}
.project-item-desc-title {
  color:@gray-moon;
  font-size:2rem;
  font-weight:700;
  margin: 0;
  padding:0 10px;
  text-transform:uppercase;
  -webkit-transform:translateY(-30px) scale(1.5);
  transform:translateY(-30px) scale(1.5);
  -webkit-transition:all 1s;
  transition:all 1s;
  will-change:transform;
  z-index:4
}
.project-item-desc-text {
  color:#fff;
  font-size:20px;
  padding:0 10px;
  -webkit-transform:translateY(50px);
  transform:translateY(50px);
  -webkit-transition:all 1s;
  transition:all 1s;
  will-change:transform
}
.wrap-all-skills{
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .skills-item{
    display: flex;
    width: 8.1rem;
    padding: 1.5rem 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    flex-shrink: 0;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    background: @dark-gray;
    border-radius: @border-radius;
    transition:all 0.5s;
    &:nth-child(9n){
      margin-right: 0;
    }
    &:hover{
      transition:all 0.5s;
      background: none;
    }
  }
  .skills-desc{
    color: @gray-moon;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    margin: 0;
  }
  .skills-img{
    width: 5.2rem;
    border-radius: 1rem;
  }
}