body {
  font-family: @font-name;
  background: @white;
  font-size: @font-size;
  color: @golden;
  padding: 0;
  margin: 0;
  background: @dark-blue;
}

::selection {
  color: @gray-moon;
  background: @golden;
}

html {
  overflow: auto;
}
p{
  margin: 0;
  padding: 0;
}
.not-home {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}

input:focus, button:focus, textarea:focus {
  outline: none;
  transition: .6s;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: @golden;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: @golden;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.mb-150 {
  margin-bottom: 150px;
}











.breadcrumbs {

  ul {
    padding-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
    li {
      list-style: none;
      color: #868686;
      padding: 0 3px;
      display: inline-block;
      font-weight: 300;
      font-size: 14px;

      a {
        color: @golden;
        font-weight: 300;
        font-size: 14px;
        padding: 0;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.wrap-container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container{
  max-width: @max-size;
  width: 100%;
}
.title-block-before{
  height: 155px;
  width: 10px;
  background: @golden;
  display: flex;
  margin-right: 20px;
}
.wrap-title-all{
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  h1{
    display: flex;
    font-family: 'Monserat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    line-height: 125%;
  }
}
.btn-link-wbg{
  border: 1px solid;
  padding: 0.7rem 1.5rem;
  border-radius:@border-radius;
  color: @golden;
  background: linear-gradient(to left, rgba(1240, 191, 108, 0) 25%, rgba(240, 191, 108, 0.9) 75%) 100% 100%;
  background-size: 400% 300%;
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover {
    transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
    color: @dark-blue;
    background-position: 1% 100%;
  }
}

.btn-link-bg{
  background: linear-gradient(to left, rgba(1240, 191, 108, 0) 25%, rgba(240, 191, 108, 0.9) 75%) 1% 100%;
  background-size: 400% 300%;
  transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover {
    transition: 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
    background-position: 100% 100%;
  }
}


.underline-btn {
  padding: 0.5em 0;
  border-radius: 0;
  text-decoration: none;
  position: relative;
  &:before {
    transition: width 0s ease, background 0.4s ease;
    left: 0;
    right: 0;
    bottom: 3px;
    content: "";
    display: block;
    position: absolute;
    height: 0.5px;
    width: 0;
  }
  &:hover:after {
    width: 97.8%;
    background: 0 0;
    transition: all 0s ease;
  }
  &:hover:before {
    width: 97.8%;
    background: @golden;
    transition: width 0.4s ease;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 0.5px;
    width: 0;
    right: 2.2%;
    bottom: 3px;
    background: @golden;
    transition: width 0.4s ease;
  }
}



.underline-hover-btn {
  text-decoration: none;
  position: relative;
  &:hover {
    //color: @golden;
  }
  &:hover:before {
    opacity: 0;
    transform: translateY(6px);
    transition-duration: 0.4s;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.5px;
    left: 0;
    bottom: 0px;
    transform: none;
    opacity: 1;
    transition: 1.8s cubic-bezier(0.2, 1, 0.3, 1);
    pointer-events: none;
  }
}
.wrap-breadcrumbs{
  display: flex;
  margin-top: 2rem;
  ul{
    list-style: none;
    padding-left: 0;
    color: @gray-moon;
    display: flex;
    gap: 7px;
    font-size: 0.875rem;
    a{
      color: @gray-moon;
      font-size: 0.875rem;
      &:hover{
        text-decoration: none;
      }
      &:before{
        background: @gray-moon;
      }
    }
  }
}