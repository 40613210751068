.wrap-footer{
  font-family: 'Montserrat', sans-serif;
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  .links-footer{
    width: 20%;
    ul{
      padding-left: 0;
      list-style: none;
      li{
        margin-bottom: 1rem;
      }
    }
    a{
      color: @gray-moon;
      font-size: 1rem;
      text-decoration: none;
      &:before{
        background: @gray-moon;
      }
    }
  }
  .contacts-footer{
    width: 50%;
    h2{
      color: @gray-moon;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
    }
    ul{
      margin-top: 2rem;
      padding-left: 0;
      list-style: none;
      li{
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        img{
          margin-right: 1rem;
        }
        a{
          color: @gray-moon;
          font-size: 1rem;
          text-decoration: none;
          &:before{
            background: @gray-moon;
          }
        }
      }
    }
  }
  .form-footer{
    width: 30%;
    .input-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 1.6rem;
      label{
        color: @gray-moon;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.04375rem;
        text-transform: uppercase;
        margin-bottom: 0.62rem;
      }
      input{
        height: 40px;
        border-radius: @border-radius;
        background: none;
        font-size: 1rem;
        font-family: 'Montserrat', sans-serif;
        letter-spacing: 1px;
        border: 1px solid @gray-moon;
        color: @gray-moon;
        padding-left: 1.5rem;
      }
      textarea{
        font-family: 'Montserrat', sans-serif;
        height: 150px;
        border-radius: @border-radius;
        font-size: 1rem;
        letter-spacing: 1px;
        border: 1px solid @gray-moon;
        color: @gray-moon;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1.5rem;
        background: none;
      }
    }
  }
  input[type="submit"]{
    width: 100%;
    //background: @golden;
    color: @dark-blue;
    text-transform: uppercase;
    height: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    border-radius: @border-radius;
    border: 1px solid @golden;
    font-weight: 500;
    &:hover{
      cursor: pointer;
      color: @golden;
    }
  }
}
